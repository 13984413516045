@font-face {
  font-family: 'Apis';
  src: local('Apis'), url(./fonts/Apis-Regular.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Apis';
  src: local('Apis-Italic'), url(./fonts/Apis-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Apis';
  src: local('Apis-Medium'), url(./fonts/Apis-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Apis';
  src: local('Apis-Bold'), url(./fonts/Apis-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Apis';
  src: local('Apis-BoldItalic'), url(./fonts/Apis-BoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}
:root {
  --primary-blue: #005AD2;
  --secondary-blue: #158BEE;
  --primary-dark-blue: #00439d;
}

/* full calendar styling */
:root .nnci_calendar {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #ddd;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2C3E50;
  --fc-button-border-color: #2C3E50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: #E2F0FA;
  --fc-now-indicator-color: red;

}


body {
  margin: 0;
  font-family: "Apis", "Roboto", "Helvetica", "Arial", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  display:none;
}

.registrant_grid {
  background-color:#ffffff;
}

.user_grid {
  background-color:#ffffff;
}


/* NNCI Events Calendar Styling */

.nnci_calendar {
  background-color: #f9f9f9;
  z-index: 1;
  position: relative;
}

.nnci_calendar .nnci_event, .nnci_calendar .nnci_event.fc-daygrid-dot-event {
  background-color: var(--primary-blue);
  color: #ffffff;
  padding: 7px;
}

.nnci_calendar .fc-daygrid-dot-event.nnci_event.fc-event-mirror, .nnci_calendar .fc-daygrid-dot-event.nnci_event:hover {
  background-color: var(--primary-blue);
  color: #ffffff;
}

.nnci_calendar .fc-direction-ltr .nnci_event.fc-daygrid-event.fc-event-end:hover, .nnci_calendar .fc-direction-rtl .nnci_event.fc-daygrid-event.fc-event-start:hover {
  background-color: var(--primary-dark-blue) !important;
}



.nnci_calendar .fc-daygrid-event-dot {
  display:none;
}

.nnci_calendar .fc-daygrid-day-top {
  justify-content: center;
  font-size: 0.9rem;
}

.nnci_calendar .fc-dayGridMonth-view .fc-col-header-cell {
  border: none;
  border-left: 1px solid var(--fc-border-color);
  font-size: 0.85rem;
  padding-top: 6px;
}

.nnci_calendar .fc-theme-standard td.fc-day-past:hover {
  background-color: #f1f1f1;
}

.nnci_calendar .fc-theme-standard td.fc-day-today:hover, .nnci_calendar .fc-theme-standard td.fc-day-future:hover {
  background-color:#E2F0FA;
}


.nnci_calendar .fc .fc-day-today .fc-daygrid-day-top {
  background-color: #005ad2;
  color: #ffffff;
  font-weight: bold;
}

.nnci_calendar .fc-theme-standard .fc-popover {
  visibility: hidden;
}

.nnci_calendar .fc .fc-daygrid-event-harness {
  overflow: hidden;
  cursor: pointer;
}

.nnci_calendar .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  left: 0;
  position: absolute;
  right: 0;
  height: calc(100% - 27px);
}

.nnci_calendar .fc .fc-daygrid-day-bottom {
  margin-top: 0px;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  bottom: 10px;
}

.fc .fc-scroller-liquid-absolute {
  overflow: hidden !important;
}


/* Activity Calendar Styling*/
.nnci_calendar .nnci_activity.holiday_event {
  display: flex;
  background-color: #D3DEED;
  opacity: 1;
}
.nnci_calendar .nnci_activity.holiday_event div {
  /* display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: normal;
  font-size: 14px;
  margin-top: -28px;
  padding: 10px; */
}

.nnci_calendar.nnci_activities_calendar .fc-day-today .fc-daygrid-day-bg {
  left: 0;
  position: absolute;
  right: 0;
  height: calc(100% - 28px);
  bottom: 1px; 
}

.nnci_calendar.nnci_activities_calendar .fc-daygrid-day-frame {
  cursor: pointer;
}


.nnci_calendar .nnci_activity, .nnci_calendar .nnci_activity.fc-daygrid-dot-event {
  /* color: #ffffff; */
  padding: 0px;
}

.nnci_calendar .fc-daygrid-dot-event.nnci_activity.fc-event-mirror, .nnci_calendar .fc-daygrid-dot-event.nnci_activity:hover {
  color: #ffffff;
}

.nnci_calendar.nnci_activities_calendar .fc-theme-standard td.fc-day-past:hover {
  background-color:#E2F0FA;
  cursor: pointer;
}

/* French styling for Full Calendar */
.fc-col-header-cell-cushion {
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  .fc-h-event .fc-event-main {
    font-size: 12px;
  }
}

/* @media (min-width: 1200px) {
  .fc-h-event .fc-event-main {
    font-size: .85em;
  }
} */